import { useContext, useEffect, useMemo, useState, MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Box, { BoxProps } from '@mui/material/Box';

// Assets
import { ReactComponent as SyrupLogoCropped } from 'Assets/syrup-logo-cropped.svg';

// Components
import MapleButton from 'Components/ds/MapleButton';
import MapleIcon from 'Components/ds/MapleIcon';
import MapleIconButton from 'Components/ds/MapleIconButton';
import MaplePopover from 'Components/MaplePopover';
import MapleTypography from 'Components/ds/MapleTypography';
import SidebarBadge from 'Components/Navigation/SidebarBadge';
import MapleDialog from 'Components/ds/MapleDialog';

// Context
import { ClientContext } from 'Context/Client';
import { ClaimContext } from 'Context/Claim';
import { DataContext } from 'Context/Data';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';

const ClaimBannerStyles = styled(Box)<BoxProps>(({ theme }) => {
  return {
    borderBottom: `1px solid ${theme.palette.stroke.soft}`,
    padding: theme.spacing(0, 0, 1),

    '.sidebar-cta__badge': {
      padding: theme.spacing(0, 2.5),

      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },

    '.sidebar-cta__main': {
      padding: theme.spacing(0, 1),

      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },

    '.sidebar-cta__main-inner': {
      backgroundColor: theme.palette.background.weak,
      padding: theme.spacing(1),
      borderRadius: '12px',
    },

    '.sidebar-cta__main.active .sidebar-cta__main-inner': {
      backgroundColor: theme.palette.primary.contrastText,
    },

    '.custom-typography-variant': {
      fontSize: '12px',
      lineHeight: '1',
    },
  };
});

const ClaimBanner = () => {
  const navigate = useNavigate();
  const { openConnectModal } = useConnectModal();
  const { connected } = useContext(ClientContext);
  const { claimState, nextClaimStartDate, currentClaimPeriod, setIsModalOpen, loading } = useContext(ClaimContext);
  const { stakingApy } = useContext(DataContext);
  const { isDesktopDevice } = useBreakpoint();

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (loading) return;
    let showBanner = false;

    if (['claim-available', 'claim-unavailable'].includes(claimState)) {
      showBanner = true;
    } else {
      const hasDismissed = checkHasDismissedBanner();
      showBanner = !hasDismissed;
    }

    setShowBanner(showBanner);
  }, [claimState, loading]);

  const checkHasDismissedBanner = () => {
    const claimBanner = localStorage.getItem('claimBanner');
    if (claimBanner === currentClaimPeriod) {
      return true;
    }
    return false;
  };

  const text = useMemo((): Record<'heading' | 'copy' | 'cta', string> => {
    if (!connected) {
      return {
        heading: 'Claim SYRUP',
        copy: 'Connect your wallet to see if you are eligible',
        cta: 'Connect Wallet',
      };
    }

    if (claimState === 'ineligible') {
      return {
        heading: 'Nothing to Claim',
        copy: 'Come back next Claim Period to see if you qualify',
        cta: nextClaimStartDate,
      };
    }

    if (claimState === 'claim-available') {
      return {
        heading: 'Claim SYRUP',
        copy: 'Claim your SYRUP and start earning',
        cta: 'Claim SYRUP',
      };
    }

    if (claimState === 'claim-unavailable') {
      return {
        heading: 'Upcoming Claim Period',
        copy: 'Come back next Claim Period to claim more Drips',
        cta: nextClaimStartDate,
      };
    }

    throw new Error('Invalid claim state');
  }, [claimState, nextClaimStartDate, connected]);

  const ineligibleOrUnavailableClaim = useMemo(() => {
    return connected && ['ineligible', 'claim-unavailable'].includes(claimState);
  }, [claimState, connected]);

  const handleCTA = ({ target }: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    if (!connected) {
      openConnectModal && openConnectModal();
      return;
    }

    if (claimState === 'claim-available') {
      setIsModalOpen(true);
      navigate('/drips');
    }

    if ((target as HTMLElement).getAttribute('data-close-banner') === 'true') {
      handleClose();
    }
  };

  const handleClose = () => {
    localStorage.setItem('claimBanner', currentClaimPeriod);
    setShowBanner(false);
  };

  if (!showBanner || !currentClaimPeriod) return null;

  return (
    <ClaimBannerStyles>
      <div className='sidebar-cta__badge'>
        <MaplePopover copy={['Claim your SYRUP and', `Stake to earn ${stakingApy.formatted}% APY`]}>
          <SidebarBadge icon='syrup' isActive={true} onClick={handleCTA} />
        </MaplePopover>
      </div>

      <div className={`sidebar-cta__main ${claimState === 'claim-available' ? 'active' : ''}`}>
        <div className='sidebar-cta__main-inner'>
          <Stack spacing={1}>
            <Stack spacing={1} sx={{ backgroundColor: 'background.white', p: 1, pb: 0, borderRadius: '8px' }}>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                {/* 🚨 : none of these values are from the DS */}
                <Box sx={{ backgroundColor: 'primary.main', padding: 1, borderRadius: '8px 10px', height: '28px' }}>
                  {/* 🚨 :used closest value to DS - h7 */}
                  <MapleTypography className='custom-typography-variant' variant='h7' color='text.white'>
                    Claim Period {ineligibleOrUnavailableClaim ? +currentClaimPeriod + 1 : currentClaimPeriod}
                  </MapleTypography>
                </Box>

                {claimState !== 'claim-available' && (
                  <MapleIconButton size='small' color='tertiary' onClick={handleClose}>
                    <MapleIcon icon='closeLine' />
                  </MapleIconButton>
                )}
              </Stack>

              <Stack alignItems='center' sx={{ width: '100%' }}>
                <SyrupLogoCropped />
              </Stack>
            </Stack>

            <Stack direction='column' sx={{ paddingX: 2.5 }}>
              <MapleTypography variant='label' size='small' textAlign='center'>
                {text.heading}
              </MapleTypography>
              {/* 🚨 :used closest value to DS - xSmall label */}
              <MapleTypography
                variant='label'
                size='xSmall'
                textAlign='center'
                sx={{ fontSize: '10px !important', fontWeight: '400 !important' }}
              >
                {text.copy}
              </MapleTypography>
            </Stack>

            {ineligibleOrUnavailableClaim ? (
              <Stack
                direction='row'
                justifyContent='center'
                spacing={1}
                sx={{
                  width: '100%',
                  borderRadius: theme => theme.shape.lg,
                  p: 1,
                  border: theme => `1px solid ${theme.palette.stroke.soft}`,
                }}
              >
                <MapleIcon icon='calendarFill' size={16} color='text.sub' />
                <MapleTypography textAlign='center' variant='label' size='xSmall'>
                  {text.cta}
                </MapleTypography>
              </Stack>
            ) : (
              <MapleButton fullWidth variant='contained' color='secondary' size='small' onClick={handleCTA}>
                {text.cta}
              </MapleButton>
            )}
          </Stack>
        </div>
      </div>
      <MapleDialog open={!isDesktopDevice && showBanner && Boolean(connected)} handleClose={handleClose}>
        <Stack spacing={4} alignItems='center'>
          <Stack
            alignItems='flex-start'
            sx={{
              width: '100%',
              backgroundColor: 'background.weak',
              borderRadius: theme => theme.shape.sm,
              overflow: 'hidden',
              p: 1,
            }}
          >
            <MapleTypography
              variant='h7'
              color='text.white'
              sx={{
                background: theme => theme.palette.gradient.linearC,
                borderRadius: theme => theme.shape.sm,
                p: 1,
                pb: 0.8,
                width: 'auto',
              }}
            >
              Claim Period {currentClaimPeriod}
            </MapleTypography>

            <Stack alignItems='center' sx={{ width: '100%', transform: 'scale(1.3)' }}>
              <SyrupLogoCropped />
            </Stack>
          </Stack>
          <Stack spacing={1} alignItems='center'>
            <MapleTypography variant='h6' textAlign='center'>
              Claim your SYRUP
            </MapleTypography>
            <MapleTypography variant='paragraph' size='small' textAlign='center'>
              Claim your SYRUP and start earning
            </MapleTypography>
          </Stack>
          <Stack spacing={1} alignItems='center' sx={{ width: '100%' }}>
            <MapleButton fullWidth onClick={handleCTA} data-close-banner='true'>
              Claim SYRUP
            </MapleButton>
            <MapleButton fullWidth color='tertiary' onClick={handleClose}>
              Cancel
            </MapleButton>
          </Stack>
        </Stack>
      </MapleDialog>
    </ClaimBannerStyles>
  );
};

export default ClaimBanner;
