import React, { createContext, useEffect, useState } from 'react';
import { AnalyticsBrowser, ID } from '@segment/analytics-next';

export interface AnalyticsContextType {
  analytics: AnalyticsBrowser | null;
  initialized: boolean;
  anonymousId: ID;
}
export const AnalyticsContext = createContext<AnalyticsContextType>({
  analytics: null,
  initialized: false,
  anonymousId: null,
});

type Props = {
  writeKey: string;
  children: React.ReactNode;
};

export const AnalyticsProvider = ({ children, writeKey }: Props) => {
  const [initialized, setInitialized] = useState(false);
  const [anonymousId, setAnonymousId] = useState<ID>(null);
  const [analytics, setAnalytics] = useState<AnalyticsBrowser | null>(null);

  useEffect(() => {
    let isMounted = true;

    const initializeAnalytics = async () => {
      try {
        // Lazy load the AnalyticsBrowser
        const { AnalyticsBrowser } = await import('@segment/analytics-next');
        const analyticsInstance = AnalyticsBrowser.load({ writeKey });

        if (!isMounted) return; // Check if component is still mounted
        setAnalytics(analyticsInstance);

        await analyticsInstance.ready();
        if (!isMounted) return; // Check again after async operation

        const id = (await analyticsInstance.user()).anonymousId();
        if (!isMounted) return; // Check again after another async operation

        setAnonymousId(id);
        setInitialized(true);
      } catch (error) {
        if (isMounted) {
          console.error('Failed to initialize analytics:', error);
        }
      }
    };

    initializeAnalytics();

    return () => {
      isMounted = false;
      analytics?.deregister();
    };
  }, [writeKey]);

  return (
    <AnalyticsContext.Provider value={{ analytics, initialized, anonymousId }}>{children}</AnalyticsContext.Provider>
  );
};
