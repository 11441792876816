export const getTimestampXMonthsLater = (timestamp: number, months: number): number => {
  const currentDate = new Date(timestamp);

  const daysToAdd = months * 30;

  currentDate.setDate(currentDate.getDate() + daysToAdd);

  return currentDate.getTime();
};

export const i18nTimestampDate = (
  timestamp: number | string,
  options = {
    day: 'numeric' as const,
    month: 'short' as const,
    year: 'numeric' as const,
  },
  language = 'en-GB',
) => {
  return new Intl.DateTimeFormat(language, options).format(new Date(timestamp));
};

export const getNow = (): string => {
  const now = new Date();

  return now.toISOString();
};

export const getTimestamp = (dateString: string): number => {
  const date = new Date(dateString);

  return Math.round(date.getTime() / 1000); // timestamp in seconds
};

export const getNowTimestamp = (): number => {
  const now = getNow();

  return getTimestamp(now);
};

export const applyCustomDateFormat = (timestamp: number | string, language = 'en') => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.toLocaleString(language, { month: 'short' });
  const year = date.getFullYear();

  return `${day}·${month}·${year}`;
};

export const applyCustomTimeFormat = (timestamp: number | string): string => {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'UTC',
  };
  const timeString = date.toLocaleString('en-US', options);

  // Remove space between time and period (AM/PM) and add "UTC"
  return `${timeString.replace(' ', '').toLowerCase()} UTC`;
};

export interface CountdownResult {
  value: number;
  unit: 'days' | 'hours' | 'minutes';
}

export const getCountdownFromTimestamp = (timestamp: number): CountdownResult | null => {
  const now = Date.now();
  const timeUntilExpiry = timestamp - now;

  if (timeUntilExpiry <= 0) {
    return null;
  }

  const days = Math.floor(timeUntilExpiry / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeUntilExpiry % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeUntilExpiry % (1000 * 60 * 60)) / (1000 * 60));

  if (days > 0) {
    return { value: days, unit: 'days' };
  } else if (hours > 0) {
    return { value: hours, unit: 'hours' };
  } else {
    return { value: minutes, unit: 'minutes' };
  }
};

export const formatDateLongMonth = (date: Date) => {
  const day = date.getUTCDate();
  const month = date.toLocaleString('en-US', { month: 'long', timeZone: 'UTC' });
  return `${day} ${month}`;
};
