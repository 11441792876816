import {
  createContext,
  ReactNode,
  ReactElement,
  useState,
  useEffect,
  useContext,
  SetStateAction,
  Dispatch,
} from 'react';

// Context
import { ClientContext } from 'Context/Client';

// Constants / Types
import { buildValueInterfaceFromParsedValues, ValueInterface } from 'Utils/valueInterface';
import {
  baseTransactionControls,
  defaultResetControls,
  ResetControls,
  TransactionConfig,
  TransactionControls,
} from 'Utils/transactionConfig';
import { STABLE_COINS_UI_DECIMALS, SYRUP_DECIMALS } from 'Constants/tokens';
import { ZERO } from 'Constants/numbers';

// Utils
import { useGetGlobalClaimDataLazyQuery, useGetUserClaimDataLazyQuery } from 'Graphql/schema';
import { defaultValueInterface } from 'Utils/valueInterface';
import { getCountdownFromTimestamp, i18nTimestampDate } from 'Utils/timeAndDates';

type OutcomeSteps = 'success' | 'fail';
type ClaimSteps = 'confirm';

type ClaimTxType = 'claim';

export type ClaimModalSteps = OutcomeSteps | ClaimSteps;

export interface ClaimFormValues {
  isStaking: boolean;
}

const defaultFormValues: ClaimFormValues = {
  isStaking: true,
};

const defaultTransactionConfig: TransactionConfig<ClaimModalSteps, ClaimTxType> = {
  flowType: 'normal',
  txType: 'claim',
  steps: ['confirm'],
  setSteps: () => {},
};

const defaultTransactionControls: TransactionControls<ClaimModalSteps> = {
  ...baseTransactionControls,
  step: 'confirm',
};

type ClaimStates = 'ineligible' | 'claim-available' | 'claim-unavailable';

interface AccountData {
  claimAmount: ValueInterface;
  claimState: ClaimStates;
  claimTxData: {
    proofs: `0x${string}`[];
    id: bigint;
  };
}

const defaultAccountData: AccountData = {
  claimAmount: { ...defaultValueInterface },
  claimState: 'ineligible',
  claimTxData: { proofs: [], id: ZERO },
};

interface GlobalData {
  currentClaimPeriod: string;
  daysLeftInCurrentClaim: string;
  nextClaimStartDate: string;
}

const defaultGlobalData: GlobalData = {
  currentClaimPeriod: '',
  daysLeftInCurrentClaim: '',
  nextClaimStartDate: '',
};

type ClaimData = TransactionConfig<ClaimModalSteps, ClaimTxType> &
  TransactionControls<ClaimModalSteps> &
  ResetControls &
  AccountData &
  GlobalData & {
    formValues: ClaimFormValues;
    setFormValues: Dispatch<SetStateAction<ClaimFormValues>>;

    loading: boolean;
  };

const defaultClaimData: ClaimData = {
  ...defaultTransactionConfig,
  ...defaultTransactionControls,
  ...defaultResetControls,
  ...defaultAccountData,
  ...defaultGlobalData,
  formValues: { ...defaultFormValues },
  setFormValues: () => {},
  loading: true,
};

export const ClaimContext = createContext<ClaimData>(defaultClaimData);

export interface ClaimProps {
  children: ReactNode;
}

export const ClaimProvider = ({ children }: ClaimProps): ReactElement => {
  const { account, web3Client } = useContext(ClientContext);

  const flowType = 'normal';
  const [steps, setSteps] = useState<ClaimModalSteps[]>(['confirm']);
  const [step, setStep] = useState<ClaimModalSteps>('confirm');
  const [isDoingTx, setIsDoingTx] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<ClaimFormValues>({ ...defaultFormValues });
  const [accountData, setAccountData] = useState<AccountData>({ ...defaultAccountData });
  const [globalData, setGlobalData] = useState<GlobalData>({ ...defaultGlobalData });

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [getUserClaimData] = useGetUserClaimDataLazyQuery();
  const [getGlobalClaimData] = useGetGlobalClaimDataLazyQuery();

  useEffect(() => {
    const initializeData = async () => {
      setIsLoading(true);

      try {
        await initAccount();
      } finally {
        setIsLoading(false);
      }
    };

    initializeData();
  }, [account, web3Client]);

  useEffect(() => {
    const initializeData = async () => {
      setIsLoading(true);
      try {
        await initGlobal();
      } finally {
        setIsLoading(false);
      }
    };
    initializeData();
  }, []);

  const initAccount = async (): Promise<void> => {
    if (!account || !web3Client) return;

    const { data } = await getUserClaimData({ variables: { id: account } });

    if (!data?.userAllocations) return;

    const currentAllocation = data?.userAllocations.find(({ globalAllocation }) => globalAllocation.active);

    if (!currentAllocation) return;

    const { amount, hasClaimed, proofs, id } = currentAllocation;

    const claimAmount = buildValueInterfaceFromParsedValues(amount, SYRUP_DECIMALS, STABLE_COINS_UI_DECIMALS);

    const claimTxData = {
      proofs: (proofs || []) as `0x${string}`[],
      id: BigInt(id),
    };

    let claimState: ClaimStates = 'ineligible';

    if (hasClaimed) {
      claimState = 'claim-unavailable';
    } else if (BigInt(amount) !== ZERO) {
      claimState = 'claim-available';
    }

    setAccountData({ claimState, claimAmount, claimTxData });
  };

  const initGlobal = async (): Promise<void> => {
    const { data } = await getGlobalClaimData();

    if (!data) return;

    const currentSeasonIndex = data.globalAllocations.findIndex(({ active }) => active);
    const currentSeason = data.globalAllocations[currentSeasonIndex];
    const nextSeason = data.globalAllocations[currentSeasonIndex + 1];

    if (!currentSeason || !nextSeason) return;

    const daysLeftInCurrentClaim = getCountdownFromTimestamp(+`${currentSeason?.deadline || 0}`); // format: 23 days
    const nextClaimStartDate = nextSeason?.start ? i18nTimestampDate(+nextSeason.start) : '-'; // format: 4 Jan 2025
    const currentClaimPeriod = `${currentSeason.number}`;

    setGlobalData({
      currentClaimPeriod,
      daysLeftInCurrentClaim: `${daysLeftInCurrentClaim?.value} ${daysLeftInCurrentClaim?.unit}`,
      nextClaimStartDate,
    });
  };

  // 🚨 --- Resets
  // ------------------------------------------------------------
  // softReset():
  // - What? -  Resets "Transaction State"
  const resetTxState = () => {
    setStep('confirm');
    setIsDoingTx(false);
  };

  // hardReset():
  // - What? -  Resets "Transaction Config" and "Transaction State"
  const resetTxConfig = () => {
    setSteps(['confirm']);
    setIsModalOpen(false);
  };

  const softReset = () => {
    resetTxState();
  };

  const hardReset = async () => {
    resetTxConfig();
    resetTxState();
  };

  const reinitialize = async () => {
    hardReset();
    setFormValues({ ...defaultFormValues });
    await initAccount();
  };

  return (
    <ClaimContext.Provider
      value={{
        // ⚙️ Transaction Config ⚙️
        flowType,
        steps,
        setSteps,
        txType: 'claim',

        // 🛠️ Transaction State 🛠️
        step,
        setStep,
        isDoingTx,
        setIsDoingTx,
        isModalOpen,
        setIsModalOpen,

        // ↩️ Resets ↩️
        softReset,
        hardReset,
        reinitialize,

        // 📖 Form Values 📖
        formValues,
        setFormValues,

        // Other values
        ...accountData,
        ...globalData,
        loading: isLoading,
      }}
    >
      {children}
    </ClaimContext.Provider>
  );
};
