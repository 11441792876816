import { MapleIconProps } from 'Components/ds/MapleIcon';

export const LEND_ROUTES = ['/lend', '/portfolio', '/drips', '/details'];
export const STAKE_ROUTES = ['/stake'];
export const CONVERT_ROUTES = ['/convert'];

export interface NavItemProps {
  text: string;
  to: string;
  icon: MapleIconProps['icon'];
}

export interface NavConfigProps extends NavItemProps {
  description: string;
  routes: string[];
}

export const navItems: NavConfigProps[] = [
  {
    text: 'Lend',
    to: '/lend',
    icon: 'coins',
    description: 'Deposit into SYRUP and start earning',
    routes: LEND_ROUTES,
  },
  {
    text: 'Stake',
    to: '/stake',
    icon: 'stackLine',
    description: 'Stake SYRUP and start earning yield',
    routes: STAKE_ROUTES,
  },
  {
    text: 'Convert',
    to: '/convert',
    icon: 'exchangeLine',
    description: 'Convert MPL into SYRUP',
    routes: CONVERT_ROUTES,
  },
];

export const supportNavItems: NavItemProps[] = [
  {
    text: 'Docs',
    to: 'https://syrup.gitbook.io/syrup',
    icon: 'filePdfFill',
  },
  {
    text: 'FAQ',
    to: 'https://syrup.gitbook.io/syrup/troubleshooting-and-support/faq',
    icon: 'fileTextFill',
  },
  {
    text: 'Terms',
    to: 'https://syrup.gitbook.io/syrup/legal/terms-of-service',
    icon: 'questionFill',
  },
];
