import { useLocation } from 'react-router-dom';

const useIsMarketingPage = () => {
  const location = useLocation();

  const isMarketingPage = location.pathname === '/';

  return isMarketingPage;
};

export default useIsMarketingPage;
