import { useState, MutableRefObject } from 'react';
import { Box, Menu, Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';

// Components
import MapleTypography from 'Components/ds/MapleTypography';
import MapleIcon from 'Components/ds/MapleIcon';

// Types
import { NavItem } from 'Components/Navigation/AppBarMarketing';

type MobileMenuProps = {
  containerRef: MutableRefObject<null>;
  navItems: NavItem[];
};

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    width: '100%',
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1.5, 3),
  },

  '& .item': {
    cursor: 'pointer',
    padding: theme.spacing(1),
    margin: theme.spacing(0, 0, 1, 0),
  },

  '& .item:last-of-type': {
    margin: 0,
  },
}));

const MobileMenu = ({ containerRef, navItems }: MobileMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = () => setAnchorEl(containerRef.current);
  const handleClose = () => setAnchorEl(null);

  return (
    <div>
      <Box onClick={handleClick} sx={{ cursor: 'pointer' }}>
        <MapleIcon icon={anchorEl ? 'closeFill' : 'menuLine'} />
      </Box>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose} container={containerRef.current}>
        {navItems.map(({ label, to, target, rel }) => (
          <Link key={label} to={to} target={target} rel={rel} style={{ textDecoration: 'none', color: 'none' }}>
            <Stack className='item' direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
              <MapleTypography size='large' variant='label' color='text.strong'>
                {label}
              </MapleTypography>

              <MapleIcon icon='arrowRight' color='#a1a1a1' />
            </Stack>
          </Link>
        ))}
      </StyledMenu>
    </div>
  );
};

export default MobileMenu;
