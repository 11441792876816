/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Merges two arrays of objects based on a field in each object.
 * If an object with the same field value exists in the existing array, it updates the existing object with the new object.
 * If not, it adds the new object to the existing array.
 * @param existing - The existing array of objects.
 * @param incoming - The incoming array of objects.
 * @param readField - A function to read the field value from an object.
 * @param fieldName - The name of the field to compare.
 * @returns The merged array of objects.
 */
export const mergeCachedArrayByField = (
  existing: any[],
  incoming: any[],
  { readField }: { readField: (field: string, item: any) => any },
  fieldName: string,
) => {
  const merged = [...existing];
  incoming.forEach(item => {
    const index = merged.findIndex(e => readField(fieldName, e) === readField(fieldName, item));
    if (index > -1) {
      merged[index] = { ...merged[index], ...item };
    } else {
      merged.push(item);
    }
  });
  return merged;
};
