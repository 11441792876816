import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.text.disabled,
  opacity: 0.3,
  borderWidth: '1px',
  margin: theme.spacing(1, 0),
  transform: 'scaleY(0.75)',
  transformOrigin: 'center',
}));

export default StyledDivider;
