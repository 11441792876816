import { useState } from 'react';
import Cookies from 'js-cookie';

export default function useCookie(name) {
  const [cookie, setCookieVal] = useState(() => Cookies.get(name) || '');

  const setCookie = (newValue: string, optionsOverride?: Cookies.CookieAttributes) => {
    const options: Cookies.CookieAttributes = {
      sameSite: 'None', // Allows cross-site cookie usage
      secure: true, // Ensure cookies are set securely
      expires: 30,
      ...optionsOverride,
    };
    Cookies.set(name, newValue, options);
    setCookieVal(newValue);
  };

  const deleteCookie = () => {
    Cookies.remove(name);
    setCookieVal('');
  };

  return {
    cookie,
    setCookie,
    deleteCookie,
  };
}
