/* eslint-disable @typescript-eslint/no-explicit-any */
import { getWalletConnectConnector, RainbowKitWalletConnectParameters, Wallet } from '@rainbow-me/rainbowkit';
import { CreateConnector, WalletDetailsParams } from '@rainbow-me/rainbowkit/dist/wallets/Wallet';
import { createConnector } from 'wagmi';
import { injected } from 'wagmi/connectors';

export type ExodusWalletOptions = {
  projectId: string;
  walletConnectParameters?: RainbowKitWalletConnectParameters;
};

const createInjectedConnector = (provider: unknown): CreateConnector => {
  return (walletDetails: WalletDetailsParams) => {
    const injectedConfig: any = {
      target: () => ({
        id: walletDetails.rkDetails.id,
        name: walletDetails.rkDetails.name,
        provider,
      }),
    };

    return createConnector(config => ({
      ...injected(injectedConfig)(config),
      ...walletDetails,
    }));
  };
};

const isAndroid = (): boolean => {
  return typeof navigator !== 'undefined' && /android/i.test(navigator.userAgent);
};

export const downloadUrls = {
  android: 'https://onelink.to/exodus-syrup',
  ios: 'https://onelink.to/exodus-syrup',
  mobile: 'https://onelink.to/exodus-syrup',
  qrCode: 'https://onelink.to/exodus-syrup',
  chrome: 'https://onelink.to/exodus-syrup',
  browserExtension: 'https://onelink.to/exodus-syrup',
};

export const exodusWallet = ({ projectId, walletConnectParameters }: ExodusWalletOptions): Wallet => {
  const isExodusInjected =
    typeof window !== 'undefined' && (window as any)?.exodus && (window as any)?.exodus?.ethereum;

  const shouldUseWalletConnect = !isExodusInjected;

  const getUri = (uri: string) => {
    return isAndroid() ? uri : `exodus://wc?uri=${encodeURIComponent(uri)}`;
  };

  return {
    id: 'exodus',
    name: 'Exodus',
    iconUrl: async () => ((await import('../Assets/icon-exodus-wallet.svg')) as any).default,
    iconBackground: '#fff',
    installed: !!isExodusInjected,
    downloadUrls,
    mobile: { getUri: shouldUseWalletConnect ? getUri : undefined },
    qrCode: shouldUseWalletConnect ? { getUri } : undefined,
    createConnector: shouldUseWalletConnect
      ? getWalletConnectConnector({
          projectId,
          walletConnectParameters,
        })
      : createInjectedConnector((window as any).exodus?.ethereum),
  };
};
