import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import Stack, { StackProps } from '@mui/material/Stack';

// Components
import MapleTypography from 'Components/ds/MapleTypography';
import MapleIcon from 'Components/ds/MapleIcon';

// Constants
import { navItems, NavConfigProps } from 'Constants/navigation';

const MobileNavStyles = styled(Stack)<StackProps>(({ theme }) => {
  return {
    padding: theme.spacing(2, 0),
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.palette.background.white,
    borderRadius: `${theme.shape.md} ${theme.shape.md} 0 0`,
    border: `1px solid ${theme.palette.stroke.sub}`,
  };
});

const NavItemStyles = styled(Stack)<StackProps>(({ theme }) => {
  return {
    color: theme.palette.text.disabled,

    svg: {
      fill: theme.palette.icon.soft,
    },

    '&.selected': {
      color: theme.palette.text.strong,

      svg: {
        fill: theme.palette.icon.strong,
      },
    },
  };
});

const MobileNav = () => {
  const { pathname } = useLocation();
  return (
    <MobileNavStyles direction='row' alignItems='center' justifyContent='center' spacing={4}>
      {navItems.map(({ text, to, icon }: NavConfigProps, i: number) => (
        <Link to={to} key={i}>
          <NavItemStyles direction='column' alignItems='center' className={pathname === to ? 'selected' : ''}>
            <MapleIcon icon={icon} />
            <MapleTypography variant='paragraph' size='small'>
              {text}
            </MapleTypography>
          </NavItemStyles>
        </Link>
      ))}
    </MobileNavStyles>
  );
};

export default MobileNav;
