import { useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Stack, { StackProps } from '@mui/material/Stack';

// Components
import MapleIcon, { MapleIconProps } from 'Components/ds/MapleIcon';
import MapleTypography from 'Components/ds/MapleTypography';

// Constants
import { LEND_ROUTES } from 'Constants/navigation';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';

interface NavItem {
  title: string;
  url: string;
  icon: MapleIconProps['icon'];
  activeIcon?: MapleIconProps['icon'];
}

const LEND_NAV_ITEMS: NavItem[] = [
  {
    title: 'Deposit',
    url: '/lend',
    icon: 'coins',
  },
  {
    title: 'Portfolio',
    url: '/portfolio',
    icon: 'pieChartFill',
  },
  {
    title: 'Drips',
    url: '/drips',
    icon: 'syrupBottleMonochrome',
    activeIcon: 'syrupBottleHalf',
  },
  {
    title: 'Details',
    url: '/details',
    icon: 'barChartFill',
  },
];

const shouldForwardProp = (prop: string) => !['isDesktopDevice'].includes(prop);

interface StyledProps extends StackProps {
  isDesktopDevice: boolean;
}

const NavBarStyles = styled(Stack, { shouldForwardProp })<StyledProps>(({ theme }) => {
  const defaultPaddingLeft = 16;

  return {
    position: 'fixed',
    left: 0,
    right: 0,
    top: '60px',
    width: '100%',
    zIndex: 9,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.white,
    boxShadow: theme.syrupShadows.sm,

    [theme.breakpoints.up('md')]: {
      paddingLeft: `${defaultPaddingLeft + 80}px`,
    },

    [theme.breakpoints.up('lg')]: {
      paddingLeft: `${defaultPaddingLeft + 216}px`,
    },
  };
});

const NavItemStyles = styled(Stack, { shouldForwardProp })<StyledProps>(({ theme, isDesktopDevice }) => {
  return {
    color: theme.palette.text.disabled,

    ...(isDesktopDevice
      ? {
          padding: theme.spacing(1, 2),
        }
      : {
          padding: theme.spacing(0.5, 1),
        }),

    svg: {
      fill: theme.palette.icon.soft,
    },

    '&.selected': {
      color: theme.palette.text.strong,
      border: `1px solid ${theme.palette.stroke.soft}`,
      boxShadow: theme.syrupShadows.md,

      borderRadius: '32px',

      svg: {
        fill: theme.palette.primary.main,
      },
    },
  };
});

const ROUTES_WITH_NAVIGATION_BLOCKED = ['/restricted'];

const NavBar = () => {
  const { pathname } = useLocation();
  const { isDesktopDevice } = useBreakpoint();

  const isNavigationBlocked = ROUTES_WITH_NAVIGATION_BLOCKED.includes(pathname);

  const navItems = useMemo((): NavItem[] => {
    if (isNavigationBlocked) return [];

    if (LEND_ROUTES.includes(pathname)) return LEND_NAV_ITEMS;

    return [];
  }, [pathname, isNavigationBlocked]);

  if (!navItems.length) return null;

  return (
    <NavBarStyles
      isDesktopDevice={isDesktopDevice}
      direction='row'
      alignItems='center'
      justifyContent={{ xs: 'space-between', md: 'center' }}
      spacing={{ xs: 0, md: 2 }}
    >
      {navItems.map(({ title, url, icon, activeIcon }, i) => (
        <Link key={i} to={!isNavigationBlocked ? url : ''}>
          <NavItemStyles
            isDesktopDevice={isDesktopDevice}
            className={pathname === url ? 'selected' : ''}
            direction='row'
            alignItems='center'
            justifyContent='center'
            spacing={1}
          >
            {activeIcon ? (
              <MapleIcon icon={pathname === url ? activeIcon : icon} size='16px' />
            ) : (
              <MapleIcon icon={icon} size='16px' />
            )}

            <MapleTypography variant='label' size='small'>
              {title}
            </MapleTypography>
          </NavItemStyles>
        </Link>
      ))}
    </NavBarStyles>
  );
};

export default NavBar;
