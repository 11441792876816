import { ReactNode } from 'react';
import { RainbowKitProvider, AvatarComponent } from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import { config } from 'Constants/network';

import '@rainbow-me/rainbowkit/styles.css';

const queryClient = new QueryClient();

interface WalletConnectProps {
  children: ReactNode;
}

const CustomAvatar: AvatarComponent = ({ address, ensImage, size }) => {
  return ensImage ? (
    <img src={ensImage} width={size} height={size} style={{ borderRadius: 999 }} />
  ) : (
    <Jazzicon diameter={size} seed={jsNumberForAddress(address || '')} />
  );
};

const WalletConnect = ({ children }: WalletConnectProps) => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider avatar={CustomAvatar}>{children}</RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default WalletConnect;
