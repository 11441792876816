import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';

// Components
import MapleButton from 'Components/ds/MapleButton';
import ResourcesMenu from 'Components/Navigation/ResourcesMenu';
import MobileMenu from 'Components/Navigation/MobileMenu';

// Constants
import { navItems } from 'Constants/navigation';

// Icons
import { ReactComponent as SyrupLogo } from 'Assets/logo-syrup-full.svg';

// Hooks
import useScrollPosition from 'Hooks/useScrollPosition';

// Theme
import { zIndex } from 'Theme/designSystem';

// Styles
const wrapper = {
  backgroundColor: { xs: 'background.weak', md: 'transparent' },
  position: 'fixed',
  width: '100vw',
  transform: theme => ({ xs: 'translateY(0px)', md: `translateY(${theme.spacing(2)})` }),
  left: 0,
  zIndex: zIndex.high,
  transition: 'box-shadow 0.3s ease',
};
const container = {
  backgroundColor: { xs: 'background.weak', md: 'transparent' },
};
const wrapperShadow = {
  boxShadow: theme => ({ xs: theme.syrupShadows.xl, md: 'none' }),
};
const stack = {
  borderRadius: { xs: 0, md: '100px' },
  backgroundColor: { xs: 'transparent', md: 'background.white' },
  width: '100%',
  py: 2.5,
  px: { xs: 0, md: 4 },
  position: 'relative',
  transition: 'box-shadow 0.3s ease',
};
const stackShadow = {
  boxShadow: theme => ({ xs: 'none', md: theme.syrupShadows.xl }),
};
const logo = {
  overflow: 'hidden',
  height: '36px',
  width: { xs: 'auto', md: '192px' },
  svg: { height: '36px', width: '121px' },
};

const AppBar = () => {
  const hasScrolled = useScrollPosition();

  return (
    <Box sx={{ ...wrapper, ...(hasScrolled && wrapperShadow) }}>
      <Container maxWidth='xl' sx={container}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{ ...stack, ...(hasScrolled && stackShadow) }}
        >
          <Stack direction='row' alignItems='center'>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <MobileMenu />
            </Box>
            <Link to='/'>
              <Box sx={logo}>
                <SyrupLogo />
              </Box>
            </Link>
          </Stack>

          <Stack direction='row' spacing={4} sx={{ display: { xs: 'none', md: 'block' } }} alignItems='center'>
            {navItems.map(({ text, to }) => (
              <Link key={to} to={to}>
                <MapleButton variant='textLink' sx={{ fontSize: '16px' }}>
                  {text}
                </MapleButton>
              </Link>
            ))}
          </Stack>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <ResourcesMenu />
            </Box>

            <Link to='/lend' style={{ textDecoration: 'none' }}>
              <MapleButton size='small' sx={{ fontSize: '14px' }}>
                Enter App
              </MapleButton>
            </Link>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default AppBar;
