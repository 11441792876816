import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h0: true; // Only used on Marketing side
    h7: true;
  }
}

export interface MapleTypographyProps extends TypographyProps {
  size?: 'xLarge' | 'large' | 'medium' | 'small' | 'xSmall' | '2xSmall';
  variant?: 'paragraph' | 'label' | 'subheading' | 'h0' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7';
}

const StyledTypography = styled(Typography)<MapleTypographyProps>(
  ({ variant = 'paragraph', size = 'medium', theme }) => {
    const { label, paragraph, subheading } = theme.typography;

    const isHeading = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(variant);

    const isExtendedHeading = ['h0', 'h7'].includes(variant);

    if (isHeading || isExtendedHeading) {
      return {
        ...theme.typography[variant],
      };
    }

    return {
      ...(variant === 'paragraph' && { ...paragraph, ...theme.typography.paragraph[size] }),
      ...(variant === 'label' && { ...label, ...theme.typography.label[size] }),
      ...(variant === 'subheading' && { ...subheading, ...theme.typography.subheading[size] }),
    };
  },
);

/**
 * Need to map custom variants to original variants to ensure correct HTML semantic rendering:
 * - paragraph: p
 * - heading: h1, h2, h3, h4, h5, h6
 * - label, subheading: span
 */
const CustomTypography = ({ variant = 'paragraph', size = 'medium', ...props }: MapleTypographyProps) => {
  if (variant === 'paragraph') return <StyledTypography component='p' variant={variant} size={size} {...props} />;

  return <StyledTypography variant={variant} size={size} {...props} />;
};

export default CustomTypography;
