import { useState } from 'react';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';

// Components
import MapleButton from 'Components/ds/MapleButton';
import MapleMenu from 'Components/ds/MapleMenu';
import MapleIcon from 'Components/ds/MapleIcon';

// Constants
import { supportNavItems } from 'Constants/navigation';

// Icons
import { RiArrowDownSLine } from '@remixicon/react';

const ResourcesMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    anchorEl?.blur();
    setAnchorEl(null);
  };

  const handleClickItem = () => {
    handleClose();
  };

  return (
    <>
      <MapleButton variant='textLink' sx={{ fontSize: '16px' }} endIcon={<RiArrowDownSLine />} onClick={handleOpen}>
        Resources
      </MapleButton>
      <MapleMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Stack spacing={1}>
          {supportNavItems.map(({ text, to, icon }) => (
            <Link key={to} to={to} target='_blank' rel='noopener noreferrer'>
              <MapleButton
                variant='textLink'
                sx={{ fontSize: '12px' }}
                startIcon={<MapleIcon icon={icon} />}
                onClick={handleClickItem}
              >
                {text}
              </MapleButton>
            </Link>
          ))}
        </Stack>
      </MapleMenu>
    </>
  );
};

export default ResourcesMenu;
