declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 430,
    md: 800,
    lg: 1000,
    xl: 1400,
  },
};

export default breakpoints;
