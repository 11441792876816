// ThemeConfig.tsx
import { FC, useMemo, ReactNode, ReactElement, useState } from 'react';
import { PaletteMode, ThemeOptions } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';

// Context
import { ColorModeContext } from 'Context/ColorMode';

// Theme
import palette from 'Theme/palette';
import { generateTypography } from 'Theme/typography';
import breakpoints from 'Theme/breakpoints';
import overrides from 'Theme/overrides';
import { syrupShadows, shape } from 'Theme/designSystem';

const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    ...(mode === 'light' ? { ...palette.light } : { ...palette.dark }),
  },
  shape,
  breakpoints,
  syrupShadows,
  // spacing: 8,
  components: overrides(),
});

interface ThemeConfigProps {
  children: ReactNode;
}

const ThemeConfig: FC<ThemeConfigProps> = ({ children }: ThemeConfigProps): ReactElement => {
  const [mode, setMode] = useState<PaletteMode>('light');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  theme.typography = {
    ...theme.typography,
    ...generateTypography(theme),
  };

  return (
    <StyledEngineProvider injectFirst>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </StyledEngineProvider>
  );
};

export default ThemeConfig;
