import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';

// Components
import MapleTypography from 'Components/ds/MapleTypography';
import MapleButton from 'Components/ds/MapleButton';
import MapleIcon from 'Components/ds/MapleIcon';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';

// Styles
const wrapper = {
  background: theme => theme.palette.gradient.linearC,
  p: 1,
  position: 'relative',
};
const text = {
  color: theme => theme.palette.text.white,
  a: {
    color: theme => theme.palette.text.white,
    svg: {
      transform: 'translate(3px, 3px)',
    },
  },
};
const textBtn = {
  color: theme => theme.palette.text.white,
};

const AppBanner = () => {
  const { isDesktopDevice } = useBreakpoint();

  if (isDesktopDevice)
    return (
      <Stack direction='row' alignItems='center' justifyContent='center' sx={wrapper} spacing={2}>
        <MapleIcon icon='syrupInverted' />
        <MapleTypography variant='paragraph' size='medium' color='text.white'>
          Syrup is Live! - Convert MPL to SYRUP
        </MapleTypography>
        <Link to='/convert'>
          <MapleButton color='secondary' opacity={0.2} size='xSmall' sx={textBtn}>
            Convert Now
          </MapleButton>
        </Link>
      </Stack>
    );

  return (
    <Stack direction='row' alignItems='center' justifyContent='start' sx={wrapper} spacing={1}>
      <MapleIcon icon='syrupInverted' />
      <MapleTypography variant='paragraph' size='small' color='text.white' sx={text}>
        Syrup is Live! -{' '}
        <Link to='/convert'>
          Convert MPL to SYRUP
          <MapleIcon icon='arrowRight' size={16} color='text.white' />
        </Link>
      </MapleTypography>
    </Stack>
  );
};

export default AppBanner;
