import { Addresses } from 'Contracts/addresses';

const addresses: Addresses = {
  mpl: '0xc3b3323166df76694cbbc4d15b147ea4edbfb302',
  xMPL: '0x789b261518fd01e8a3e31716639174343ae92f19',
  syrupToken: '0x6647d269ead8e6a3ae018f5a9943dcfb7b385444',
  stSyrup: '0x79c2384d1287456e2cfdd0162ce4db5529aa50a4',
  syrupTokenMigrator: '0xf94d89d5774eeead17d213ec16a8a069274323e6',
  mplUserActions: '0xf738ea428a294b300c1e94422f31cd3582ca6de5',
  syrupDrip: '0x0052f76eb837a54f7908b75a0df898566a50c714',
  pendleLP: '0x0000000000000000000000000000000000000000',
  pendlePT: '0x0000000000000000000000000000000000000000',
  pendleYT: '0x0000000000000000000000000000000000000000',
};

export default addresses;
