import { useContext } from 'react';
import { RestrictedContext } from 'Context/Restricted';

const useRestricted = () => {
  const context = useContext(RestrictedContext);
  if (!context) throw new Error('Context used outside of its Provider!');

  return context;
};

export default useRestricted;
