// LIST OF TODO'S
// 1. Check colors used into the states

import { styled } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { hexToRgba } from 'Utils/styles';
import { ColorStyles } from './MapleButton';

declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides {
    xxSmall: true;
    xSmall: true;
  }

  interface IconButtonPropsVariantOverrides {
    outlined: false;
  }

  interface IconButtonPropsColorOverrides {
    default: false;
    error: false;
    info: false;
    success: false;
    warning: false;
    tertiary: true;
  }
}

interface MapleIconButtonProps extends IconButtonProps {
  opacity?: number;
}

const setBackgroundOpacity = (colorStyles: ColorStyles, opacity: number) => {
  return {
    ...colorStyles,
    backgroundColor: hexToRgba(colorStyles.backgroundColor, opacity),
    '&:hover': {
      ...colorStyles['&:hover'],
      backgroundColor: hexToRgba(colorStyles['&:hover'].backgroundColor, opacity),
    },
    '&:active': {
      ...colorStyles['&:active'],
      backgroundColor: hexToRgba(colorStyles['&:active'].backgroundColor, opacity),
    },
    '&:disabled': {
      ...colorStyles['&:disabled'],
      backgroundColor: hexToRgba(colorStyles['&:disabled'].backgroundColor, opacity),
    },
  };
};

const StyledButton = styled(IconButton)<MapleIconButtonProps>(
  ({ theme, size = 'large', color = 'primary', opacity }) => {
    const sizeStyles = {
      xxSmall: {
        width: '24px',
        height: '24px',
        padding: theme.spacing(0.5),
        svg: {
          width: '12px',
          height: '12px',
        },
      },
      xSmall: {
        width: '32px',
        height: '32px',
        svg: {
          width: '16px',
          height: '16px',
        },
      },
      small: {
        width: '36px',
        height: '36px',
        svg: {
          width: '16px',
          height: '16px',
        },
      },
      medium: {
        width: '44px',
        height: '44px',
        svg: {
          width: '18px',
          height: '18px',
        },
      },
      large: {
        width: '56px',
        height: '56px',
        svg: {
          width: '20px',
          height: '20px',
        },
      },
    };

    const colorStyles: Record<'primary' | 'secondary' | 'tertiary', ColorStyles> = {
      primary: {
        backgroundColor: theme.palette.background.strong,
        color: theme.palette.text.white,
        boxShadow: theme.syrupShadows.md,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: theme.palette.primary.dark,
          boxShadow: 'none',
        },
        '&:disabled': {
          backgroundColor: theme.palette.background.soft,
          color: theme.palette.text.disabled,
          boxShadow: 'none',
        },
      },
      secondary: {
        backgroundColor: theme.palette.background.white,
        color: theme.palette.text.strong,
        boxShadow: theme.syrupShadows.lg,
        border: `0.5px solid ${theme.palette.stroke.soft}`,
        '&:hover': {
          backgroundColor: theme.palette.background.white,
          boxShadow: theme.syrupShadows.sm,
        },
        '&:active': {
          backgroundColor: theme.palette.background.weak || '',
          borderColor: theme.palette.background.weak || '',
          boxShadow: 'none',
        },
        '&:disabled': {
          backgroundColor: theme.palette.background.weak || '',
          borderColor: theme.palette.background.weak || '',
          color: theme.palette.text.disabled,
          boxShadow: 'none',
        },
      },
      tertiary: {
        backgroundColor: theme.palette.background.weak || '',
        color: theme.palette.text.strong,
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: theme.palette.background.weak || '',
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: theme.palette.background.weak || '',
          boxShadow: 'none',
        },
        '&:disabled': {
          backgroundColor: theme.palette.background.weak || '',
          color: theme.palette.text.disabled,
          boxShadow: 'none',
        },
      },
    };

    return {
      textTransform: 'none',
      fontStyle: 'normal',
      borderWidth: 0,
      borderRadius: theme.shape.round,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...sizeStyles[size],
      ...(opacity !== undefined ? setBackgroundOpacity(colorStyles[color], opacity) : colorStyles[color]),
    };
  },
);

const MapleIconButton = ({ ...props }: MapleIconButtonProps) => {
  return <StyledButton disableRipple {...props} />;
};

export default MapleIconButton;
