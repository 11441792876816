import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import Stack, { StackProps } from '@mui/material/Stack';

// Components
import MapleDivider from 'Components/ds/MapleDivider';
import ClaimBanner from 'Components/ClaimBanner';
import SidebarBadge from 'Components/Navigation/SidebarBadge';
import SidebarFooter from 'Components/Navigation/SidebarFooter';
import SidebarNavItem from 'Components/Navigation/SidebarNavItem';
import MaplePopover from 'Components/MaplePopover';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';

// Types
import { navItems, NavConfigProps } from 'Constants/navigation';

interface WrapperProps extends StackProps {
  isDesktopDevice?: boolean;
}

const SidebarWrapper = styled(Stack)<WrapperProps>(({ theme }) => {
  return {
    height: 'calc(100vh - 60px)',
    top: '60px',
    left: 0,
    borderRight: `1px solid ${theme.palette.stroke.soft}`,
    backgroundColor: theme.palette.background.white,
    position: 'fixed',
    padding: theme.spacing(1.5, 0, 0, 0),
    zIndex: theme.zIndex.appBar,

    [theme.breakpoints.between('sm', 'lg')]: {
      width: '80px',
    },

    [theme.breakpoints.up('lg')]: {
      width: '216px',
    },
  };
});

const NavigationSidebar = () => {
  const { pathname } = useLocation();
  const { breakPoint } = useBreakpoint();

  return (
    <SidebarWrapper justifyContent='space-between' className='sidebar'>
      <Stack direction='column' spacing={1} justifyContent='space-between' sx={{ height: '100%' }}>
        <Stack direction='column' spacing={1} sx={{ px: 2.5, height: '100%' }}>
          {navItems.map(({ text, to, icon, routes }: NavConfigProps, i) => (
            <Link key={i} to={to}>
              {['xl', 'xxl'].includes(breakPoint) ? (
                <SidebarNavItem text={text} icon={icon} isActive={routes.includes(pathname)} />
              ) : (
                <MaplePopover copy={text}>
                  <SidebarBadge icon={icon} isActive={routes.includes(pathname)} />
                </MaplePopover>
              )}

              {i === 0 && <MapleDivider />}
            </Link>
          ))}
        </Stack>

        <Stack direction='column' spacing={2}>
          <ClaimBanner />

          <SidebarFooter />
        </Stack>
      </Stack>
    </SidebarWrapper>
  );
};

export default NavigationSidebar;
