export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_DAY = 24 * 60 * 60; // 86,400
export const SECONDS_IN_HOUR = 60 * 60; // 3,600
export const SECONDS_IN_YEAR = 365 * SECONDS_IN_DAY;
export const SECONDS_PER_DAY = 60 * 60 * 24;
export const MILLISECONDS_PER_DAY = SECONDS_PER_DAY * 1000;

export const DATE_FORMAT = {
  day: "numeric",
  month: "short",
  year: "numeric",
};

export const TIME_FORMAT = {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

export const DATE_FORMAT_TIME = {
  ...DATE_FORMAT,
  hour: "numeric",
  minute: "numeric",
};
