import { EthereumProvider } from '@walletconnect/ethereum-provider';
import { SafeAppProvider } from '@safe-global/safe-apps-provider';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';

export type GnosisConnectionTypes = 'none' | 'gnosis-default' | 'gnosis-wallet-connect';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function checkIfMetadataUrlIsSafe(provider: any): boolean {
  return provider.signer?.session?.peer.metadata.url.startsWith('https://app.safe.global');
}

const useIsGnosisSafe = (): GnosisConnectionTypes => {
  const [gnosisConnectionType, setGnosisConnectionType] = useState<GnosisConnectionTypes>('none');

  const { connector } = useAccount();

  useEffect(() => {
    // Detect if connection is via Gnosis Safe by comparing connector instance
    const fetchProvider = async () => {
      if (!connector?.getProvider) return;

      let connection: GnosisConnectionTypes = 'none';
      const provider = await connector.getProvider();
      if (!provider) return;

      // Check if connected to Safe via Safe UI
      const isSafeConnector = provider instanceof SafeAppProvider;
      if (isSafeConnector) connection = 'gnosis-default';

      // Check if connected to Safe via Wallet Connect
      const isWalletConnectConnector = provider instanceof EthereumProvider;
      const isMetadataUrlSafe = checkIfMetadataUrlIsSafe(provider);
      const isGnosisSafeWithWalletConnect = isWalletConnectConnector && isMetadataUrlSafe;
      if (isGnosisSafeWithWalletConnect) connection = 'gnosis-wallet-connect';

      setGnosisConnectionType(connection);
    };

    fetchProvider();
  }, [connector]);

  return gnosisConnectionType;
};

export default useIsGnosisSafe;
