import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box, { BoxProps } from '@mui/material/Box';
import { Link } from 'react-router-dom';

// Components
import ConnectWallet from 'Components/ConnectWallet';
import NavBarV2 from 'Components/Navigation/NavBarV2';
import SupportNav from 'Components/Navigation/SupportNav';

// Icons
import { ReactComponent as SyrupLogo } from 'Assets/logo-syrup-full.svg';
import { ReactComponent as SyrupLogoMobile } from 'Assets/logo-syrup-mobile.svg';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';

const AppBarStyles = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'fixed',
  left: 0,
  right: 0,
  zIndex: 10,
  height: '60px',
  padding: theme.spacing(1.5, 2.5),
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.white,
  borderBottom: `1px solid ${theme.palette.stroke.soft}`,
  boxShadow: theme.syrupShadows.xl,
}));

const stack = {
  backgroundColor: 'background.white',
  width: '100%',
  position: 'relative',
  transition: 'box-shadow 0.3s ease',
};

const logo = {
  overflow: 'hidden',
  height: '36px',
  width: { xs: '36px', md: '80px' },
  svg: { height: '36px', width: { xs: '36px', md: '80px' } },
};

const AppBar = () => {
  const { isDesktopDevice } = useBreakpoint();

  return (
    <AppBarStyles>
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ ...stack }}>
        <Link to='/'>
          <Box sx={logo}>{isDesktopDevice ? <SyrupLogo /> : <SyrupLogoMobile />}</Box>
        </Link>
        <Stack direction='row' alignItems='center' spacing={1}>
          {!isDesktopDevice && <SupportNav />}
          <ConnectWallet />
        </Stack>
      </Stack>

      <NavBarV2 />
    </AppBarStyles>
  );
};

export default AppBar;
