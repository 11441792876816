import { ReactNode, MouseEvent, useState } from 'react';
import { styled } from '@mui/material';

import Popover from '@mui/material/Popover';

// Components
import MapleTypography from 'Components/ds/MapleTypography';

const StyledPopover = styled(Popover)(({ theme }) => {
  return {
    '.MuiPaper-root': {
      borderRadius: theme.shape.md,
      border: `1px solid ${theme.palette.stroke.soft}`,
      padding: theme.spacing(1.5),
      boxShadow: theme.syrupShadows.lg,
      margin: theme.spacing(0, 0, 0, 2.125),
    },

    '.MuiTypography-root': {
      padding: 0,
      display: 'block',
    },
  };
});

interface MaplePopoverProps {
  copy: string[] | string;
  children: ReactNode;
}

const MaplePopover = ({ children, copy }: MaplePopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <span
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </span>
      <StyledPopover
        id='mouse-over-popover'
        sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {Array.isArray(copy) ? (
          copy.map((paragraph, index) => (
            <MapleTypography key={index} variant='label' size='medium' color='text.strong'>
              {paragraph}
            </MapleTypography>
          ))
        ) : (
          <MapleTypography variant='label' size='medium' color='text.strong'>
            {copy}
          </MapleTypography>
        )}
      </StyledPopover>
    </>
  );
};

export default MaplePopover;
