import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import App from './App.tsx';

import './polyfills.js';

Sentry.init({
  denyUrls: [/localhost/, /https:\/\/www\.googletagmanager\.com\/gtag\/js\?id=.*/],
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_PROJECT ?? 'unknown',
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'User rejected the request.', // When user cancels request from the wallet interface
    'wallet must has at least one account', // When selected wallet doesn't have valid accounts
  ],
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 50% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.01, // 1% of normal traffic
  replaysOnErrorSampleRate: 1.0, // 100% of errors
  beforeSend(event) {
    const eventString = JSON.stringify(event);
    if (eventString.includes('node_modules')) {
      return null;
    }
    // Google Tag manager could be blocked by browsers and we want to silient this error
    if (eventString.includes('googletagmanager')) {
      return null;
    }

    // Continue to send the event if it doesn't match the above criteria
    return event;
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);
