import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';

// Theme
import { zIndex } from 'Theme/designSystem';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  zIndex: zIndex.highest, // Force the drawer to cover the Intercom CTA

  '& .MuiDrawer-paper': {
    borderRadius: `${theme.shape.lg} ${theme.shape.lg} 0 0`,
    border: theme => `1px solid ${theme.palette.stroke.soft}`,
  },

  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    backdropFilter: 'blur(12.5px)',
  },

  '.CloseButton': {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
  },
}));

export default StyledDrawer;
