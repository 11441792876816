import { createContext, useState, useEffect } from 'react';
import useCookie from 'Hooks/useCookies';
import { useLocation } from 'react-router-dom';

type AppModalType = 'terms-conditions' | 'onboarding' | 'pendle';

export interface AppModalContextType {
  openModal: (modal: AppModalType) => void;
  completeModal: (modal: AppModalType, value: string) => void;
  isModalOpen: (modal: AppModalType) => boolean;
}

export const AppModalContext = createContext<AppModalContextType | undefined>(undefined);

const PATHNAMES_WITHOUT_MODALS = ['/convert', '/stake', '/'];

export const AppModalProvider = ({ children }) => {
  const { pathname } = useLocation();
  const [openModals, setOpenModals] = useState<AppModalType[]>([]);
  const { cookie: termsCookieSet, setCookie: setTermsCookie } = useCookie('terms-conditions');
  const { cookie: onboardingCookieSet, setCookie: setOnboardingCookie } = useCookie('onboarding');
  const { cookie: pendleCookieSet, setCookie: setPendleCookie } = useCookie('pendle');

  useEffect(() => {
    if (PATHNAMES_WITHOUT_MODALS.includes(pathname)) setOpenModals([]);
    else if (!termsCookieSet) {
      setOpenModals(['terms-conditions']);
    } else if (!onboardingCookieSet && pathname === '/lend') {
      setOpenModals(['onboarding']);
    } else if (!pendleCookieSet) {
      setOpenModals(['pendle']);
    }
  }, [pathname, termsCookieSet, onboardingCookieSet, pendleCookieSet]);

  const openModal = (modal: AppModalType) => {
    setOpenModals(prev => [...prev, modal]);
  };

  const completeModal = (modal: AppModalType, value: string) => {
    setOpenModals(prev => prev.filter(m => m !== modal));

    if (modal === 'pendle') setPendleCookie(value);
    if (modal === 'terms-conditions') setTermsCookie(value, { expires: undefined }); // Never expires
    if (modal === 'onboarding') setOnboardingCookie(value, { expires: undefined }); // Never expires
  };

  const isModalOpen = (modal: AppModalType): boolean => {
    return openModals.includes(modal);
  };

  return (
    <AppModalContext.Provider value={{ openModal, completeModal, isModalOpen }}>{children}</AppModalContext.Provider>
  );
};
