import { parseUnits } from 'viem/utils';

export const parseUserInputWithDecimals = (userInputs: string, unitDecimals: number): bigint => {
  const trimmedUserInputs = userInputs.trim();
  const userInputWithoutComas = trimmedUserInputs.replaceAll(',', '');
  const [, userInputDecimals] = userInputWithoutComas.split('.');
  let value;
  if (userInputWithoutComas.endsWith('.') || userInputDecimals?.length > (unitDecimals ?? 0)) {
    value = parseUnits(userInputWithoutComas.slice(0, -1), unitDecimals);
  } else {
    value = parseUnits(userInputWithoutComas, unitDecimals);
  }

  return value;
};
