import { useRef, useState } from 'react';
import { Menu, styled } from '@mui/material';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

import { NavItemProps, supportNavItems } from 'Constants/navigation';
import MapleTypography from 'Components/ds/MapleTypography';
import MapleIcon from 'Components/ds/MapleIcon';

const openIconStyles = {
  cursor: 'pointer',
  backgroundColor: 'background.white',
  border: theme => `1px solid ${theme.palette.stroke.soft}`,
  borderRadius: '50%',
  width: '35px',
  height: '35px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5),
  },
}));

const SupportNav = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const containerRef = useRef(null);
  const handleClick = () => setAnchorEl(containerRef.current);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Box ref={containerRef} onClick={handleClick} sx={{ ...openIconStyles }}>
        <MapleIcon width='15px' icon='ellipsis' />
      </Box>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose} container={containerRef.current}>
        <Stack direction='column' alignItems='flex-start' justifyContent='space-between' spacing={1.5}>
          {supportNavItems.map(({ text, to, icon }: NavItemProps, i: number) => (
            <Link key={i} to={to} target='_blank' rel='noopener noreferrer'>
              <Stack direction='row' alignItems='center' justifyContent='center' spacing={1}>
                <MapleIcon icon={icon} color='text.soft' height='16px' width='16px' />
                <MapleTypography variant='label' size='small' color='text.soft'>
                  {text}
                </MapleTypography>
              </Stack>
            </Link>
          ))}
        </Stack>
      </StyledMenu>
    </>
  );
};

export default SupportNav;
