import { useContext } from 'react';
import { AnalyticsContext } from 'Context/SegmentAnalytics';

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('Context used outside of its Provider!');
  }
  // if (!context.initialized) {
  //   console.warn('Analytics instance not yet initialized');
  // }

  return context;
};
