import { ConnectButton } from '@rainbow-me/rainbowkit';
import { styled } from '@mui/material/styles';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import Stack from '@mui/material/Stack';

// Components
import MapleButton from './ds/MapleButton';
import MapleIcon from './ds/MapleIcon';



const CustomConnectWalletButtonStyles = styled(Stack)(({ theme }) => {
  return {
    '.MuiButton-root': {
      padding: '10px 14px !important'
    }
  }
})

const ConnectWalletButton = () => {
  return (
    <ConnectButton.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
        const connected = mounted && account && chain;

        const handleOpenConnectModal = () => {
          openConnectModal();
        };

        const handleOpenAccountModal = () => {
          openAccountModal();
        };

        return (
          <CustomConnectWalletButtonStyles direction='row' spacing={1} alignItems="center">
            {(() => {
              if (!connected) {
                return (
                  <MapleButton onClick={handleOpenConnectModal} size='large'>
                    Connect Wallet
                  </MapleButton>
                );
              }

              if (chain.unsupported) {
                return (
                  <MapleButton onClick={openChainModal} size='large'>
                    Wrong network
                  </MapleButton>
                );
              }

              return (
                <>
                  <MapleIcon icon='ethereum' width={36} height={36} color='icon.weak' />
                  <MapleButton
                    onClick={handleOpenAccountModal}
                    size='large'
                    color='secondary'
                    startIcon={<Jazzicon diameter={16} seed={jsNumberForAddress(account.address)} />}
                  >
                    {account.displayName}
                    {account.ensAvatar}
                  </MapleButton>
                </>
              );
            })()}
          </CustomConnectWalletButtonStyles>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default ConnectWalletButton;
