import { Dispatch, SetStateAction } from 'react';

export type FlowType = 'normal' | 'permit';

// ⚙️ Transaction Config ⚙️
// ------------------------------
export interface TransactionConfig<Steps, TxType> {
  flowType: FlowType;
  steps: Steps[];
  setSteps: Dispatch<SetStateAction<Steps[]>>;
  txType: TxType;
}

// 🛠️ Transaction Controls 🛠️
// ------------------------------
export interface TransactionControls<Steps> {
  step: Steps;
  setStep: Dispatch<SetStateAction<Steps>>;
  isDoingTx: boolean;
  setIsDoingTx: Dispatch<SetStateAction<boolean>>;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const baseTransactionControls = {
  setStep: () => {},
  isDoingTx: false,
  setIsDoingTx: () => {},
  isModalOpen: false,
  setIsModalOpen: () => null,
};

// Reset controls
// ------------------------------
export interface ResetControls {
  softReset: () => void;
  hardReset: () => void;
  reinitialize: () => void;
}

export const defaultResetControls: ResetControls = {
  softReset: () => {},
  hardReset: () => {},
  reinitialize: () => {},
};
