import { styled } from '@mui/material/styles';

import Stack, { StackProps } from '@mui/material/Stack';

// Components
import MapleTypography from 'Components/ds/MapleTypography';
import SidebarBadge from 'Components/Navigation/SidebarBadge';

// Types
import { MapleIconProps } from 'Components/ds/MapleIcon';

const shouldForwardProp = (prop: string) => !['isActive'].includes(prop);

interface SidebarNavItemStylesProps extends StackProps {
  isActive: boolean;
}

const SidebarNavItemStyles = styled(Stack, { shouldForwardProp })<SidebarNavItemStylesProps>(({ theme, isActive }) => {
  return {
    borderRadius: theme.shape.sm,

    '& .MuiTypography-root': {
      visibility: 'collapse',
    },

    ...(isActive && {
      backgroundColor: theme.palette.primary.contrastText,
      border: `1px solid ${theme.palette.primary.light}`,
    }),

    [theme.breakpoints.up('lg')]: {
      '& .MuiTypography-root': {
        visibility: 'visible',
      },
    },
  };
});

interface SidebarNavItemProps {
  text: string;
  icon: MapleIconProps['icon'];
  isActive: boolean;
}

const SidebarItem = ({ text, icon, isActive }: SidebarNavItemProps) => {
  return (
    <SidebarNavItemStyles spacing={1} direction='row' alignItems='center' key={text} isActive={isActive}>
      <SidebarBadge icon={icon} isActive={isActive} />
      <MapleTypography variant='label' size='small' color={`text.${isActive ? 'primary' : 'soft'}`}>
        {text}
      </MapleTypography>
    </SidebarNavItemStyles>
  );
};

export default SidebarItem;
