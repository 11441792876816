import { ApolloClient, InMemoryCache, HttpLink, DefaultOptions } from '@apollo/client';
// import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
// import { sha256 } from 'crypto-hash';
import { API_URL } from 'Services/ApolloClient/constants';
import { mergeCachedArrayByField } from 'Utils/apolloClient';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

// const persistedQueriesLink = createPersistedQueryLink({ sha256 });

const httpLink = new HttpLink({ uri: `${API_URL}/v2/graphql` });

// Enable persisted queries globally
// const v2Link = persistedQueriesLink.concat(httpLink);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        syrupGlobals: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        openTermLoans: {
          merge(existing = [], incoming, { readField }) {
            return mergeCachedArrayByField(existing, incoming, { readField }, 'id');
          },
        },
      },
    },
    SyrupGlobals: {
      keyFields: [], // This tells Apollo that SyrupGlobals is a singleton
    },
    OpenTermLoan: {
      keyFields: ['id'],
    },
  },
});

export default new ApolloClient({
  cache,
  link: httpLink,
  connectToDevTools: true,
  defaultOptions,
  name: 'Syrup',
  version: process.env.REACT_APP_VERSION ?? 'localhost',
});
