import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

// Hooks
import { useAnalytics } from 'Hooks/useAnalytics';

// Components
import MapleTypography from 'Components/ds/MapleTypography';
import MapleIcon from 'Components/ds/MapleIcon';

// Icons
import { ReactComponent as SyrupLogo } from 'Assets/syrup-logo-footer-marketing.svg';

const communityLinks = [
  { label: 'Telegram', href: 'https://t.me/+6UdN_NkuRroyNGRl' },
  { label: 'X-Twitter', href: 'https://x.com/syrupfi' },
];

const aboutLinks = [
  { label: 'Docs', href: 'https://syrup.gitbook.io/syrup' },
  // { label: 'Terms & Conditions', href: '/' },
];

const StyledList = styled('div')(({ theme }) => ({
  textAlign: 'left',
  '& .MuiTypography-label': {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    li: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      a: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
      },
    },
  },
}));

const ListGroup = ({ title, items }) => {
  const { analytics, initialized } = useAnalytics();

  const fireMixpanelEvent = (_: React.MouseEvent, label: string) => {
    if (initialized) analytics?.page(label);
  };

  return (
    <StyledList>
      <MapleTypography variant='label' size='xSmall'>
        {title}
      </MapleTypography>
      <ul>
        {items.map(({ label, href }) => (
          <li key={label}>
            <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
              <MapleIcon icon='bulletPoint' size={12} />
              <Link to={href} target='_blank' rel='noopener noreferrer'>
                <MapleTypography variant='paragraph' size='xLarge' onClick={e => fireMixpanelEvent(e, label)}>
                  {label}
                </MapleTypography>
              </Link>
            </Stack>
          </li>
        ))}
      </ul>
    </StyledList>
  );
};

const FooterMarketing = () => {
  return (
    <Box sx={{ width: '100vw' }}>
      <Container maxWidth='xl' sx={{ pt: 8, position: 'relative' }}>
        <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={14}>
          <Stack direction='column' alignItems='flex-start' spacing={{ xs: 4, md: 16 }}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={8}>
              <ListGroup title='Community' items={communityLinks} />

              <ListGroup title='About' items={aboutLinks} />
            </Stack>

            <MapleTypography variant='label' size='medium'>
              {`©Syrup ${new Date().getFullYear()}`}
            </MapleTypography>
          </Stack>
          <Box
            textAlign='right'
            sx={{
              width: { xs: '200px', md: '400px' },
              height: { xs: '150px', md: '300px' },
              overflow: { xs: 'visible', md: 'hidden' },
              svg: { transform: 'rotate(-35deg)', width: { xs: '200px', md: '400px' }, height: 'auto' },
            }}
          >
            <SyrupLogo />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default FooterMarketing;
