import { MouseEventHandler } from 'react';
import { styled } from '@mui/material/styles';

import Box, { BoxProps } from '@mui/material/Box';

// Components
import MapleIcon, { MapleIconProps } from 'Components/ds/MapleIcon';

interface IconBadgeProps extends BoxProps {
  isActive: boolean;
}

const shouldForwardProp = (prop: string) => !['isActive'].includes(prop);

const IconBadgeWrapper = styled(Box, { shouldForwardProp })<IconBadgeProps>(({ theme, isActive }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: theme.shape.sm,

    ...(isActive
      ? {
          backgroundColor: theme.palette.primary.contrastText,
        }
      : {
          backgroundColor: 'transparent',
        }),

    '.badge__outer': {
      borderRadius: '10px',
      padding: theme.spacing(0.75),
    },

    '.badge__inner': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '28px',
      height: '28px',
      borderRadius: '8px',

      ...(isActive
        ? {
            backgroundColor: theme.palette.primary.main,
          }
        : {
            backgroundColor: theme.palette.background.weak,
          }),
    },
  };
});

interface SidebarBadgeProps {
  isActive: boolean;
  icon: MapleIconProps['icon'];
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
}

const SidebarBadge = ({ isActive, icon, onClick }: SidebarBadgeProps) => {
  return (
    <IconBadgeWrapper isActive={isActive} onClick={onClick}>
      <div className='badge__outer'>
        <div className='badge__inner'>
          <MapleIcon icon={icon} color={isActive ? 'icon.white' : 'icon.soft'} size='15px' />
        </div>
      </div>
    </IconBadgeWrapper>
  );
};

export default SidebarBadge;
