import { ReactElement, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { watchAccount } from '@wagmi/core';
import { Address } from 'viem';

import {
  AssetFragment,
  FunctionLevelBitmapFragment,
  GetAccountQuery,
  GetGlobalDataQuery,
  GetGlobalSyrupStatsCachedQuery,
  PoolFragment,
  useGetAccountQuery,
  useGetGlobalDataQuery,
  useGetGlobalSyrupStatsCachedQuery,
} from 'Graphql/schema';

// Context
import { ClientContext } from 'Context/Client';

// Constants
import {
  APY_DECIMALS,
  PERCENTAGE_UI_DECIMALS,
  POLLING_INTERVAL,
  USDC_DECIMALS,
  RATE_DECIMALS,
  TEN,
  config,
} from 'Constants';
import { ADDRESSES } from 'Contracts/addresses';

// Utils
import { accountHasPermission } from 'Utils/account';
import { defaultAssetData, defaultPoolData } from 'Utils/defaultValues';
import {
  buildPercentageValueInterfaceFromBigNumber,
  buildValueInterfaceFromBigNumberValues,
  EMPTY_PERCENTAGE,
  ValueInterface,
  defaultValueInterface,
} from 'Utils/valueInterface';

import { getStakingApy, getStakingRPCData } from 'Utils/calculations/staking';

type PermissionsMap = Record<FunctionIDs, boolean>;
type PermissionsMapById = Record<Address, PermissionsMap>;

type FunctionIDs =
  | 'P:deposit'
  | 'P:depositWithPermit'
  | 'P:mint'
  | 'P:mintWithPermit'
  | 'P:redeem'
  | 'P:removeShares'
  | 'P:requestRedeem'
  | 'P:requestWithdraw'
  | 'P:withdraw';

const defaultPermissionsMap: PermissionsMap = {
  'P:deposit': false,
  'P:depositWithPermit': false,
  'P:mint': false,
  'P:mintWithPermit': false,
  'P:redeem': false,
  'P:removeShares': false,
  'P:requestRedeem': false,
  'P:requestWithdraw': false,
  'P:withdraw': false,
};

const defaultPermissionsMapById: Record<Address, PermissionsMap> = {
  '0x00000': { ...defaultPermissionsMap },
  '0x00001': { ...defaultPermissionsMap },
};

type Contracts = 'poolUSDC' | 'usdc' | 'routerUSDC' | 'poolUSDT' | 'usdt' | 'routerUSDT' | 'mpl' | 'xMPL';

const defaultAddresses: Record<Contracts, Address | null> = {
  poolUSDC: null,
  usdc: null,
  routerUSDC: null,
  poolUSDT: null,
  usdt: null,
  routerUSDT: null,
  mpl: null,
  xMPL: null,
};

interface GlobalData {
  pools: PoolFragment[];
  lendingAssets: AssetFragment[];
  addresses: Record<Contracts, Address | null>;
  dripsYieldBoost: ValueInterface;
  combinedApy: ValueInterface;
  weightedApy: ValueInterface;
  weightedTvl: ValueInterface;
  stakingApy: ValueInterface;
}

interface AccountData {
  isSyrupLender: boolean;
  permissionsMapByPoolId: PermissionsMapById;
}

interface ContextData extends GlobalData, AccountData {
  accountLoading: boolean;
  syrupGlobalLoading: boolean;
  refetchAccount: () => void;
  refetchGlobal: () => void;
}

const defaultGlobalData: GlobalData = {
  pools: [{ ...defaultPoolData }],
  lendingAssets: [{ ...defaultAssetData }],
  addresses: { ...defaultAddresses },
  dripsYieldBoost: { ...defaultValueInterface },
  combinedApy: { ...EMPTY_PERCENTAGE },
  weightedApy: { ...EMPTY_PERCENTAGE },
  weightedTvl: { ...defaultValueInterface },
  stakingApy: { ...defaultValueInterface },
};

const defaultAccountData: AccountData = {
  isSyrupLender: false,
  permissionsMapByPoolId: { ...defaultPermissionsMapById },
};

const defaultContextData: ContextData = {
  ...defaultGlobalData,
  ...defaultAccountData,
  accountLoading: false,
  syrupGlobalLoading: false,
  refetchAccount: () => null,
  refetchGlobal: () => null,
};

export const DataContext = createContext<ContextData>({ ...defaultContextData });

export interface DataProps {
  children: ReactNode;
}

export const DataProvider = ({ children }: DataProps): ReactElement => {
  const { account } = useContext(ClientContext);

  const [theGlobalData, setTheGlobalData] = useState<GlobalData>({ ...defaultGlobalData });
  const [theAccountData, setTheAccountData] = useState<AccountData>({ ...defaultAccountData });

  const {
    data: syrupStatsData,
    loading: syrupStatsLoading,
    refetch: refetchSyrupStats,
  } = useGetGlobalSyrupStatsCachedQuery({
    pollInterval: POLLING_INTERVAL,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: globalData,
    loading: globalLoading,
    refetch: refetchGlobal,
  } = useGetGlobalDataQuery({
    variables: { stSyrupAddress: ADDRESSES.stSyrup },
    pollInterval: POLLING_INTERVAL,
  });

  const {
    data: accountData,
    loading: accountLoading,
    refetch: refetchAccount,
  } = useGetAccountQuery({
    variables: { id: account?.toLowerCase() ?? '' },
    skip: !account,
  });

  useEffect(() => {
    // loading
    if (syrupStatsLoading || globalLoading) return;

    // reset
    if (!syrupStatsData || !globalData) {
      setTheGlobalData({ ...defaultGlobalData });
      return;
    }

    // init
    initGlobalData(globalData, syrupStatsData);
  }, [syrupStatsData, globalData, syrupStatsLoading, globalLoading]);

  useEffect(() => {
    // loading
    if (accountLoading || globalLoading) return;

    const unwatch = watchAccount(config, {
      onChange(account) {
        if (account.isConnecting) {
          setTheAccountData({ ...defaultAccountData });
        }
      },
    });

    // reset
    if (!accountData || !account || !globalData) {
      setTheAccountData({ ...defaultAccountData });
      return;
    }

    // init
    initAccount(accountData, globalData?.poolV2S);

    return () => {
      unwatch();
    };
  }, [accountLoading, account, accountData, globalData, globalLoading]);

  const initGlobalData = async (data: GetGlobalDataQuery, syrupStatsData: GetGlobalSyrupStatsCachedQuery) => {
    const syrupPools = data?.poolV2S as PoolFragment[];

    if (!syrupPools || !syrupPools[0]?.syrupRouter) {
      setTheGlobalData({ ...defaultGlobalData });
      return;
    }

    const dripsYieldBoost = buildPercentageValueInterfaceFromBigNumber(
      BigInt(syrupStatsData.syrupGlobals.dripsYieldBoost ?? 0),
      RATE_DECIMALS,
      PERCENTAGE_UI_DECIMALS,
    );

    const weightedApy = buildPercentageValueInterfaceFromBigNumber(
      BigInt(syrupStatsData.syrupGlobals.apy),
      APY_DECIMALS,
      PERCENTAGE_UI_DECIMALS,
    );

    const apyToRateScaling = TEN ** BigInt(APY_DECIMALS - RATE_DECIMALS);
    const combinedApy = buildPercentageValueInterfaceFromBigNumber(
      weightedApy.bigNumber + dripsYieldBoost.bigNumber * apyToRateScaling,
      APY_DECIMALS,
      1,
    );

    const weightedTvl = buildValueInterfaceFromBigNumberValues(BigInt(syrupStatsData.syrupGlobals.tvl), USDC_DECIMALS);

    const lendingAssets = data?.poolV2S
      .reduce((acc, { asset }) => {
        if (!asset) return acc;

        const assetIndex = acc.findIndex(({ id }) => id === asset.id);

        if (assetIndex === -1) {
          acc.push(asset);
        }

        return acc;
      }, [] as AssetFragment[])
      .sort((a, b) => a.symbol.localeCompare(b.symbol));

    let addresses: Record<Contracts, Address | null> = { ...defaultAddresses };

    addresses = syrupPools.reduce((acc, { id, asset, syrupRouter }) => {
      if (!asset || !syrupRouter) return acc;

      const assetSymbol = asset.symbol.toLowerCase() as 'usdc' | 'usdt';

      acc[`pool${assetSymbol.toUpperCase()}` as Contracts] = id as Address;
      acc[`router${assetSymbol.toUpperCase()}` as Contracts] = syrupRouter.id as Address;
      acc[assetSymbol.toLowerCase() as Contracts] = asset.id as Address;

      return acc;
    }, {} as Record<Contracts, Address>);

    addresses = { ...addresses, ...ADDRESSES };

    // Staking Apy
    const { totalAssets } = await getStakingRPCData();
    let stakingApy = { ...defaultValueInterface };

    if (data?.stSyrup && totalAssets) {
      const { vestingPeriodFinish, issuanceRate } = data.stSyrup;

      stakingApy = getStakingApy({
        issuanceRate,
        totalAssets,
        vestingPeriodFinish: +vestingPeriodFinish,
      });
    }

    setTheGlobalData(prevState => ({
      ...prevState,
      pools: data?.poolV2S,
      lendingAssets,
      addresses,
      combinedApy,
      dripsYieldBoost,
      weightedApy,
      weightedTvl,
      stakingApy,
    }));
  };

  const initAccount = async (accountData: GetAccountQuery, pools: PoolFragment[]) => {
    const permissionsMapByPoolId = pools.reduce((acc, { functionLevelBitmaps, permissionLevel, id }) => {
      const thePermissionMap = functionLevelBitmaps?.reduce(
        (acc, { functionId, bitmap }: FunctionLevelBitmapFragment) => {
          const hasPermissions = accountHasPermission({
            accountBitmap: accountData?.account?.permissionsBitmap || 0,
            functionBitmap: BigInt(bitmap),
            permissionLevel,
          });

          acc[functionId as FunctionIDs] = hasPermissions;

          return acc;
        },
        {} as Record<FunctionIDs, boolean>,
      );

      acc[id] = thePermissionMap;

      return acc;
    }, {} as PermissionsMapById);

    setTheAccountData({
      isSyrupLender: accountData?.account?.isSyrupLender ?? false,
      permissionsMapByPoolId,
    });
  };

  const refetch = () => {
    refetchSyrupStats();
    refetchGlobal();
  };

  return (
    <DataContext.Provider
      value={{
        ...theGlobalData,
        ...theAccountData,
        accountLoading,
        syrupGlobalLoading: syrupStatsLoading || globalLoading,
        refetchAccount,
        refetchGlobal: refetch,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
