import { useState } from 'react';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

// Components
import MapleIconButton from 'Components/ds/MapleIconButton';
import MapleIcon from 'Components/ds/MapleIcon';
import MapleTypography from 'Components/ds/MapleTypography';
import MapleButton from 'Components/ds/MapleButton';

// Constants
import { navItems, supportNavItems, NavConfigProps, NavItemProps } from 'Constants/navigation';

// Styles
const menu = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: theme => `calc(100vh - ${theme.spacing(10.5)})`,
  backgroundColor: 'background.weak',
  transform: 'translateY(-100%)',
  transition: 'transform 0.3s ease, opacity 0.3s 0.1s ease',
  opacity: 0,
  pt: 4,
  px: 2,
  zIndex: -1,
  mt: theme => theme.spacing(10.5),
  '&.open': {
    transform: 'translateY(0)',
    opacity: 1,
  },
};
const navItemsWrapper = {
  backgroundColor: 'background.white',
  py: 3,
  px: 2,
  borderRadius: theme => theme.shape.md,
};
const navItem = {
  p: 1,
  borderRadius: theme => theme.shape.md,
  '.icon-border': {
    width: '48px',
    height: '48px',
    borderRadius: theme => theme.shape.md,
    backgroundColor: theme => theme.palette.background.weak,
  },
  '.icon-wrapper': {
    width: '32px',
    height: '32px',
    borderRadius: theme => theme.shape.sm,
    backgroundColor: theme => theme.palette.background.white,
  },
  '&:hover': {
    background: theme => theme.palette.background.weak,
    '.icon-border': {
      background: theme => theme.palette.background.white,
    },
    '.icon-wrapper': {
      background: theme => theme.palette.background.weak,
    },
  },
};
const divider = { borderColor: theme => theme.palette.background.fade };

const NavItem = ({ text, to, icon, description }: NavConfigProps) => (
  <Link to={to}>
    <Stack direction='row' spacing={1} sx={navItem} alignItems='center'>
      <Stack justifyContent='center' alignItems='center' className='icon-border'>
        <Stack justifyContent='center' alignItems='center' className='icon-wrapper'>
          <MapleIcon icon={icon} size={16} />
        </Stack>
      </Stack>
      <Stack spacing={0}>
        <MapleTypography variant='h6'>{text}</MapleTypography>
        <MapleTypography variant='paragraph' size='small' color='text.sub'>
          {description}
        </MapleTypography>
      </Stack>
    </Stack>
  </Link>
);

const SupportNavItem = ({ text, to, icon }: NavItemProps) => (
  <Link to={to} target='_blank' rel='noopener noreferrer'>
    <MapleButton variant='textLink' startIcon={<MapleIcon icon={icon} />}>
      {text}
    </MapleButton>
  </Link>
);

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <MapleIconButton size='medium' color='tertiary' onClick={() => setIsOpen(!isOpen)}>
        <MapleIcon icon={isOpen ? 'closeFill' : 'menuLine'} />
      </MapleIconButton>
      <Stack sx={menu} className={isOpen ? 'open' : ''} spacing={1}>
        <Stack sx={navItemsWrapper} spacing={4}>
          {navItems.map(navItemProps => (
            <NavItem key={navItemProps.to} {...navItemProps} />
          ))}
        </Stack>
        <Stack
          sx={navItemsWrapper}
          direction='row'
          spacing={1}
          justifyContent='space-between'
          divider={<Divider orientation='vertical' flexItem sx={divider} />}
        >
          {supportNavItems.map(supportNavItemsProps => (
            <SupportNavItem key={supportNavItemsProps.to} {...supportNavItemsProps} />
          ))}
        </Stack>
      </Stack>
    </>
  );
};

export default MobileMenu;
