import { Address } from 'viem';

import { PROJECT } from 'Constants/network';
import localhostAddresses from 'Contracts/addresses/localhost';
import mainnetProdAddresses from 'Contracts/addresses/mainnet-prod';
import sepoliaDevAddresses from 'Contracts/addresses/sepolia-dev';
import sepoliaProdAddresses from 'Contracts/addresses/sepolia-prod';


type MapleContracts = 'syrupTokenMigrator' | 'mpl' | 'xMPL' | 'syrupToken' | 'stSyrup' | 'mplUserActions' | 'syrupDrip';
type PendleContracts = 'pendleLP' | 'pendlePT' | 'pendleYT';
type Contracts = MapleContracts | PendleContracts;

export type Addresses = Record<Contracts, Address>;

const ADDRESSES_MAP: Record<string, Addresses> = {
  localhost: localhostAddresses,
  'mainnet-prod': mainnetProdAddresses,
  'sepolia-dev': sepoliaDevAddresses,
  'sepolia-prod': sepoliaProdAddresses
};

export const ADDRESSES = ADDRESSES_MAP[PROJECT];
