import { PERMISSION_LEVELS } from 'Constants';

export interface AccountHasPermissionProps {
  functionBitmap: bigint | undefined;
  permissionLevel: number;
  accountBitmap: bigint | undefined;
  // account: string;
  // allowedLPs: string[];
}

export const accountHasPermission = ({
  functionBitmap,
  permissionLevel,
  accountBitmap,
}: AccountHasPermissionProps): boolean => {
  // if (!account) return false;

  // const isAllowedLP = allowedLPs.includes(account.toLowerCase());

  // if (isAllowedLP || openToPublic || permissionLevel === PERMISSION_LEVELS.PUBLIC) {
  //   return true;
  // }

  // if (permissionLevel === PERMISSION_LEVELS.PRIVATE) return isAllowedLP;

  if (permissionLevel === PERMISSION_LEVELS.FUNCTION_LEVEL) {
    if (!functionBitmap || !accountBitmap) return false;

    return (BigInt(functionBitmap) & BigInt(accountBitmap)) === BigInt(functionBitmap);
  }

  return false;
};
