import { Project, PROJECT } from 'Constants';

const API_URLS: Record<Project, string> = {
  'sepolia-prod': 'https://sepolia.api.maple.finance',
  'sepolia-dev': 'https://sepolia.api.maple-dev.finance',
  'mainnet-dev': 'https://api.maple-dev.finance',
  'mainnet-prod': 'https://api.maple.finance',
  localhost: 'http://localhost:4000',
};

export const API_URL = API_URLS[PROJECT] || API_URLS['mainnet-prod'];
