import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';

// Components
import MapleTypography from 'Components/ds/MapleTypography';

// Types
import { NavItem } from 'Components/Navigation/AppBarMarketing';

type NavBarProps = {
  navItems: NavItem[];
};

const stack = {
  backgroundColor: 'transparent',
  py: { xs: 2, md: 0 },
};

const NavBar = ({ navItems }: NavBarProps) => {
  return (
    <Stack direction='row' alignItems='center' justifyContent='center' spacing={10} sx={stack}>
      {navItems.map(({ label, to, target, rel }) => (
        <Link key={label} to={to} target={target} rel={rel} style={{ textDecoration: 'none', color: 'none' }}>
          <MapleTypography variant='label' size='medium' color='text.strong'>
            {label}
          </MapleTypography>
        </Link>
      ))}
    </Stack>
  );
};

export default NavBar;
