import { useMemo } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

interface IUseBreakpoint {
  isDesktopDevice: boolean;
  breakPoint: 'sm' | 'md' | 'lg' | 'xxl';
}

const useBreakpoint = (): IUseBreakpoint => {
  const { breakpoints } = useTheme();

  const { sm, md, lg } = breakpoints.values;

  const isDesktopDevice = useMediaQuery(breakpoints.up('md'));

  const mqSm = useMediaQuery(breakpoints.down(sm));
  const mqMd = useMediaQuery(breakpoints.between(sm, md));
  const mqLg = useMediaQuery(breakpoints.between(md, lg));

  const breakPoint = useMemo(() => {
    if (mqSm) {
      return 'sm';
    }
    if (mqMd) {
      return 'md';
    }
    if (mqLg) {
      return 'lg';
    }

    return 'xxl';
  }, [mqSm, mqMd, mqLg]);

  return {
    breakPoint,
    isDesktopDevice,
  };
};

export default useBreakpoint;
