export const hexToRgba = (hex: string, alpha: number) => {
  const red = parseInt(hex.slice(1, 3), 16);
  const green = parseInt(hex.slice(3, 5), 16);
  const blue = parseInt(hex.slice(5, 7), 16);

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

/**
 * Converts a hex color code to a hex color code with opacity.
 * @param {string} hex - The hex color code (e.g., '#ff5733').
 * @param {number} [alpha=1] - The opacity level, where 0 is fully transparent and 1 is fully opaque.
 * @returns {string} The hex color code with the alpha value appended (e.g., '#ff5733ff').
 */
export const hexWithOpacity = (hex: string, alpha = 1) => {
  hex = hex.replace('#', '');
  const alphaHex = Math.round(alpha * 255)
    .toString(16)
    .padStart(2, '0');
  return `#${hex}${alphaHex}`;
};
